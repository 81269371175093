import { useLoaderData } from "@remix-run/react";
import { json } from "@remix-run/node";
import type { LoaderFunction } from "@remix-run/node";
import { RelatedMediaSection } from "~/shared";
import {
  ThemenschwerpunkteList,
  InfoAboutRu,
  // HowItWork,
  CardInfoSection,
  PartnersLogos,
} from "~/screens";

import {
  // fetchThemenschwerpunkteListItem,
  FetchPartnerData,
  getchHomePageData,
} from "~/api";
import mediaImage from "~/assets/images/inkusion/inkusion-media-section.svg";
import config from "~/config.server";

export const loader: LoaderFunction = async ({ context, request }) => {
  // const url = new URL(request.url);

  // const {
  //   teamMembers,
  //   inklusionCards,
  //   recomendationSection,
  //   infoSection,
  //   media,
  // } = await fetchThemenschwerpunkteListItem();

  const { mediaPartners, cooperationPartners } = await FetchPartnerData();
  const {
    media: mediaData,
    // linkCards,
    // teamMembers,
    thematicFoci,
  } = await getchHomePageData();

  const cards = [
    {
      anchor: "https://ueber.ru-digital.de/",
      description: "Erfahre mehr über das Projekt und das Team.",
      id: 0,
      title: "Über ru-digtal:",
      icon: "cursor-information",
    },
    {
      anchor: "https://newsletter.ru-digital.de/",
      description:
        "Erhalte regelmäßig Informationen zu neuen Medien und aktuellen Themen.",
      id: 1,
      title: "Newsletter abonnieren",
      icon: "newspaper-fold",
    },
    {
      anchor: "https://blog.ru-digital.de/",
      description: "Informiere dich über aktuelle Entwicklungen.",
      id: 2,
      title: "NewsBlog lesen",
      icon: "mail-advertising-envelope",
    },
    {
      anchor: "https://join.ru-digital.de",
      description: "Entdecke Möglichkeiten das Projekt zu unterstützen.",
      id: 3,
      title: "ru-digital unterstützen",
      icon: "support",
    },
    {
      anchor: " https://docs.ru-digital.de/",
      description:
        "Erhalte Hilfe bei der Nutzung von ru-digital oder dem rechtssicheren Einsatz von Medien",
      id: 4,
      title: "Hilfe Center",
      icon: "help-wheel",
    },
  ];
  let theme = thematicFoci.map((item: any) => {
    return {
      ...item,
      // finalUrl: config.BASEURL + item.picture.file.url,
    };
  });
  return json(
    {
      mediaData,
      cards,
      thematicFoci: theme,
      mediaPartners,
      cooperationPartners,
      baseUrl: config.BASEURL,
    },
    {
      headers: {
        // max-age controls the browser cache
        // s-maxage controls a CDN cache
        "Cache-Control": "public, max-age=30, s-maxage=86400",
      },
    }
  );
  // return redirect("/themenschwerpunkte");
};
// export const meta: V2_MetaFunction<typeof loader> = ({ data }) => {
//   console.log("========data homee", data);
//   return [
//     { title: "themenschwerpunkte" },
//     // {
//     //   property: "og:title",
//     //   content: data.name,
//     // },
//     // {
//     //   name: "description",
//     //   content: data.description,
//     // },
//   ];
// };
export default function Home() {
  // const location = useLocation();
  const {
    mediaData,
    cards,
    thematicFoci,
    cooperationPartners,
    mediaPartners,
    baseUrl,
  } = useLoaderData<{
    mediaData: Array<any>;
    cards: any;
    thematicFoci: Array<any>;
    mediaPartners: Array<any>;
    cooperationPartners: Array<any>;
    baseUrl: string;
  }>();
  // console.log("🚀 ~ file: home.tsx:534 ~ Home ~ datas:", thematicFoci);
  return (
    <div className="flex h-full flex-col">
      <div className="mt-4 md:mt-8">
        <ThemenschwerpunkteList data={thematicFoci} />
      </div>
      <div className="relative">
        <div className="-z-10 hidden h-full w-full flex-col pt-0 md:flex">
          <div className="absolute  top-0 w-full">
            <img alt="" className="w-full object-bottom" src={mediaImage} />
          </div>
        </div>
        <div className="flex w-full flex-col bg-slate-50 md:pb-20 md:pt-10">
          <RelatedMediaSection
            // need to set filter attributes later
            to="/search/main"
            masonry={true}
            mediaCards={mediaData}
            title={
              <div>
                <h2 className="text-2xl font-bold leading-[1.625] text-textColor-primary lg:text-[64px]">
                  Aktuelle Medien
                </h2>
                <p className="mt-2 max-w-6xl text-[18px] leading-[1.556] text-textColor-primary">
                  Lass dich inspirieren und entdecke neue Möglichkeiten für
                  deinen Religionsunterricht!
                </p>
              </div>
            }
          />
        </div>
      </div>

      <div className="md:mt-24">
        <InfoAboutRu
          title="ru-digital: mehr als nur eine Suchmaschine"
          body="<p>ru-digital unterstützt dich bei der Suche in der umfangreichsten Auswahl handverlesener unterrichtspraktischer Medien für den Religionsunterricht im Internet.
          ru-digital bietet dir Hilfe bei der Planung für den Einsatz von Medien in der Praxis.
          ru-digital ist der digitaler Begleiter für deinen Religionsunterricht.</p>"
        />
      </div>
      {/* <div className="mt-10 md:mt-20">
        <HowItWork title="How it works" body="test" />
      </div> */}
      <div className="mt-8">
        <CardInfoSection cards={cards} />
      </div>
      <div className="mt-8">
        <PartnersLogos
          cards={cooperationPartners}
          title="Kooperationspartner"
          baseUrl={baseUrl}
        />
      </div>
      <div className="mt-2">
        <PartnersLogos
          cards={mediaPartners}
          title="PARTNER"
          baseUrl={baseUrl}
        />
      </div>
    </div>
  );
}
